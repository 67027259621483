.product {
  width: 30%;
  height: 40vh;
  margin: 60px 10px;
  border: 2px solid gainsboro;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  position: relative;
}

.product-browser {
  height: 25px;
  background-color: gainsboro;
  display: flex;
  align-items: center;
  position: sticky;
  z-index: 2;
}

.product-circle-wrapper {
  position: absolute;
  display: flex;
}

.product-circle {
  width: 6px;
  margin: 3px;
  height: 6px;
  border-radius: 50%;
  background-color: white;
}

.product-image {
  width: 100%;
  transition: all 10s ease;
}

.product:hover .product-image {
  transform: translateY(-20%);
}

.product-description {
  font-size: 15px;
  font-weight: 300;
  margin: 2px 0;
  text-align: center;
  width: 100%;
}

@media screen and (max-width: 480px) {

  .product {
    width: 100%;
    margin: 20px 10px;

  }
}
