.ProductList-container {
  height: 100vh;
  position: relative;
}

.productList {
  padding: 50px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.productList-texts {
  width: 65%;
}

.productList-title {
  font-size: 32px;
  font-weight: 300;
}

.productList-description {
  margin: 20px 0px;
}

.productList-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between
}

.productList-overlay {
  position: relative;
}

@media screen and (max-width: 480px) {

  .productList {
    padding: 10px;
  }

  .productList-texts {
    width: 100%;
  }

  .productList-description {
    display: none;
  }

  .productList-title {
    font-size: 30px;
    font-weight: 300;
  }

  .ProductList-container {
    height: auto;
  }

}
