.contact {
  height: 100vh;
  position: relative;
}

.contact-bg {
  width: 20px;
  height: 100%;
  background-color: bisque;
  position: absolute;
}

.contact-wrapper {
  padding: 50px;
  display: flex;
}
.contact-left {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.contact-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-title {
  font-size: 30px;
  width: 80%;
  font-weight: 300;
}

.contact-info-item {
  display: flex;
  align-items: center;
  margin: 50px 0;
  font-weight: 300;
}

.contact-icon {
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

.contact-description {
  font-weight: 200;
}

form {
  margin-top: 20px;
  width: 50%;
}

input {
  width: 100%;
  height: 50px;
  border: none;
  border-bottom: 1px solid black;
  margin: 10px 0;
  font-size: 14px;
  padding-left: 10px;
}

textarea {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  min-height: 50px;
  max-height: 150px;
  margin: 10px 0;
  font-size: 14px;
  padding-left: 10px;
}

button {
  border: none;
  padding: 10px;
  background-color: aqua;
  color: black;
  font-weight: 500;
  border-radius: 10%;
  cursor: pointer;
}

@media screen and (max-width: 480px) {
  .contact-wrapper {
    flex-direction: column;
    padding: 0px 50px;
  }

  form {
    margin-top: 0px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .contact-title {
    font-size: 30px;
    width: 100%;
    padding: 20px 0;
    font-weight: 300;
  }
}
