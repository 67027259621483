.about {
  height: 100vh;
  display: flex;
  align-items: center;
}

.about-left {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
}

.about-right {
  flex: 1;
}

.about-card.bg {
  position: absolute;
  top: 50px;
  left: 50px;
  background-color: black;
}

.about-card {
  width: 60%;
  height: 70vh;
  border-radius: 30px;
  position: absolute;
  overflow: hidden;

}

.about-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about-title {
  font-weight: 300;
  font-size: 30px;
}

.about-sub {
  margin: 20px 0;
}

.about-description {
  font-weight: 300;
  margin: 0 15px 15px 0;
}

.about-description-platforms {
  font-weight: 300;
  margin: 15px 0;
}

.about-social-container {
  padding: 15px 0;
}

a.about-social {
  margin: 0 1rem;
  transition: transform 250ms;
  display: inline-block;
}

a.about-social:hover {
  transform: translateY(-2px);
}

a.github {
  color: #6e5494;
}

a.linkedin {
  color: #006192;
}

a.instagram {
  color: #C13584;
}

@media screen and (max-width: 480px) {
  .about {
    flex-direction: column;
    text-align: center;
    margin-top: 50px;
  }

  .about-left {
    width: 100%;
  }

  .about-card {
    height: 30vh;
  }

  .about-card.bg {
    display: none;
  }

  .about-right {
    padding: 20px;
  }
}
